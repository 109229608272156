import { ApiHelper } from "./helper/newuiApiHelper";
// import axios from "axios";
const apiHelper = new ApiHelper();
export class DataConnection {
  constructor() {}
  // getHeaders() {
  //     var token = sessionStorage.getItem("Token");
  //     return {
  //       Authorization: token,
  //     };
  // }
  createConnection(data) {
    const uri = `health-monitor-module/adverity/connection`;
    return apiHelper.post(uri, data);
  }
  getConnectedChannels(orgNumber) {
    const uri = `health-monitor-module/v1/getorgnumber?orgNumber=${orgNumber}`;
    return apiHelper.get(uri);
  }
  getAllBrands(userId, orgNumber) {
    const uri = `user-module/v1/get-brands?id=${orgNumber}&userId=${userId}`;
    return apiHelper.get(uri);
  }
  getBrands(id) {
    console.log(id);
    const uri = `user-module/v1/brands?id=${id}&page=1&size=20`;
    return apiHelper.get(uri);
    //     return axios
    // .get('http://34.226.103.91:8080/api/' + uri, {
    //   headers: this.getHeaders(),
    //   withCredentials: false,
    // })
    // .then(this.checkResponse)
    // .catch(this.handleError);
  }
  // getAllBrands(userId, orgNumber) {
  //     const uri = `user-module/v1/get-brands?id=${orgNumber}&userId=${userId}`;
  //     return axios
  // .get('http://34.226.103.91:8080/api/' + uri, {
  //   headers: this.getHeaders(),
  //   withCredentials: false,
  // })
  // .then(this.checkResponse)
  // .catch(this.handleError);
  // }
  getConnectionTableData(currentPage, perPage) {
    const uri = `health-monitor-module/connection_summary?orgNumber=${sessionStorage.getItem(
      "subId"
    )}&page=${currentPage}&size=${perPage}`;
    return apiHelper.get(uri);
  }
  getsearchConnectionName(
    connectionName,
    currentPage,
    perPage,
    orgNumber = sessionStorage.getItem("subId")
  ) {
    const uri = `health-monitor-module/search_connections?connectionName=${connectionName}&orgNumber=${orgNumber}&page=${currentPage}&size=${perPage}`;
    return apiHelper.get(uri);
  }
  getMccs(typeId, adverityConnectionId) {
    const uri = `health-monitor-module/adverity/getmccs?typeId=${typeId}&adverityConnectionId=${adverityConnectionId}`;
    return apiHelper.get(uri);
  }
  getAccounts(typeId, reportType, adverityConnectionId, mccs) {
    const uri = `health-monitor-module/adverity/getclient_customers?typeId=${typeId}&mccsid=${mccs}&repreporttype=${reportType}&auth=${adverityConnectionId}`;
    return apiHelper.get(uri);
  }
  createDataStream(data, channelTypeId) {
    const uri = `health-monitor-module/adverity?typeId=${channelTypeId}`;
    return apiHelper.post(uri, data);
  }
  fetchData(data) {
    const uri = `health-monitor-module/fetch`;
    return apiHelper.post(uri, data);
  }
  getDataStreamSummaryTableData(currentPage, perPage) {
    const uri = `health-monitor-module/adverity/datastreams?page=${currentPage}&size=${perPage}&orgNumber=${sessionStorage.getItem(
      "subId"
    )}&role=${sessionStorage.getItem("Role")}&clientId=${sessionStorage.getItem(
      "clientId"
    )}`;
    return apiHelper.get(uri);
  }
  deleteDataStreamById(dataStreamId) {
    console.log(dataStreamId);
    const uri = `health-monitor-module/adverity/deletedatastream?adverityDatastreamId=${dataStreamId}&orgNumber=${sessionStorage.getItem(
      "subId"
    )}`;
    return apiHelper.post(uri);
  }
  deleteConnectionById(connectionId) {
    console.log(+connectionId);
    const uri = `health-monitor-module/adverity/deleteConnectionSummary?ConnectionId=${+connectionId}&orgNumber=${sessionStorage.getItem(
      "subId"
    )}`;
    return apiHelper.get(uri);
  }
  getChannelList() {
    const uri = `health-monitor-module/unifiedcampaign/v1/channel?orgNumber=${sessionStorage.getItem(
      "subId"
    )}`;
    return apiHelper.get(uri);
  }
  getDataStreamByChannel(channel) {
    const uri = `health-monitor-module/unifiedcampaign/v1/orgnumber/channel?orgNumber=${sessionStorage.getItem(
      "subId"
    )}&channelNames=${channel}`;
    return apiHelper.get(uri);
  }
  getCampaignList(data) {
    const uri = `health-monitor-module/unifiedcampaign/v1/campaign-name`;
    return apiHelper.post(uri, data);
  }
  getsearchDataStream(
    dataStreamName,
    currentPage,
    perPage,
    orgNumber = sessionStorage.getItem("subId"),
    clientId = sessionStorage.getItem("clientId")
  ) {
    const uri = `health-monitor-module/adverity/search_datastreams?clientId=${clientId}&searchKey=${dataStreamName}&orgNumber=${orgNumber}&page=${currentPage}&size=${perPage}`;
    return apiHelper.get(uri);
  }
  getSnapchatAdAccount(accounts, auth, typeId) {
    const uri = `health-monitor-module/adverity/get-adaccounts?term=${accounts}&auth=${auth}&typeId=${typeId}`;
    return apiHelper.get(uri);
  }
  getYoutubeChannels(auth, dataType, contentOwnerId, typeId) {
    const uri = `health-monitor-module/adverity/get-youtube-channel?auth=${auth}&dataType=${dataType}&contentOwnerId=${contentOwnerId}&typeId=${typeId}`;
    return apiHelper.get(uri);
  }
  getFacebookInsightsPages(auth, typeId) {
    const uri = `health-monitor-module/adverity/getfacebookinsight-pages?auth=${auth}&typeId=${typeId}`;
    return apiHelper.get(uri);
  }
  getCountryList(text, auth, typeId) {
    const uri = `health-monitor-module/adverity/get-keyword-location?term=${text}&auth=${auth}&typeId=${typeId}`;
    return apiHelper.get(uri);
  }
  getUnifiedCampaignDetails(dataStreamId) {
    const uri = `health-monitor-module/unifiedcampaign/init?datastreamIds=${dataStreamId}`;
    return apiHelper.get(uri);
  }
  postNewCampaignData(unifiedCampaignRequest) {
    const uri = `health-monitor-module/unifiedcampaign`;
    return apiHelper.post(uri, unifiedCampaignRequest);
  }
  getExistingUnifiedCampaignList() {
    const uri = `health-monitor-module/unifiedcampaign?orgNumber=${sessionStorage.getItem(
      "subId"
    )}`;
    return apiHelper.get(uri);
  }
  getCountry(clientId, type, country, channel) {
    const uri = `health-monitor-module/rhyme/${type}?clientName=${clientId}&country=${country}&channel=${channel}`;
    return apiHelper.get(uri);
  }
  validateData(orgNumber, category) {
    const uri = `health-monitor-module/kpi/validation?orgNumber=${orgNumber}&category=${category}`;
    return apiHelper.get(uri);
  }
  downloadFile(filename, category) {
    const uri = `health-monitor-module/template/csv?channelType=${category}`;
    return apiHelper.downloadFile(filename, uri);
  }
  downloadFileNewTemplate(filename) {
    const uri = `excel-upload/v1/download/masterFormat`;
    return apiHelper.downloadFile(filename, uri);
  }
  uploadFile(
    data,
    clientId,
    orgNumber,
    brandId,
    channelCategory,
    channelType,
    datastreamName,
    channel,
    modelName
  ) {
    const uri = `excel-upload/v1/upload?clientId=${clientId}&orgNumber=${orgNumber}&brandId=${brandId}&channelCategory=${channelCategory}&dataStreamName=${datastreamName}&channelType=${channelType}&channelName=${channel}&model=${modelName}`;
    return apiHelper.uploadFile(uri, data);
  }
  googleTrendsKeyword(keyword, auth, typeId) {
    const uri = `health-monitor-module/adverity/get-keyword-location?term=${keyword}&auth=${auth}&typeId=${typeId}`;
    return apiHelper.get(uri);
  }
  getConnectionWithoutDataStream(orgNumber, page, size) {
    const uri = `health-monitor-module/connection_summary?orgNumber=${orgNumber}&page=${page}&size=${size}`;
    return apiHelper.get(uri);
  }
  validateConnectionName(orgNumber, connectionName) {
    const uri = `health-monitor-module/validate-name?orgNumber=${orgNumber}&name=${connectionName}`;
    return apiHelper.get(uri);
  }
  getAllConnectionList() {
    const uri = `health-monitor-module/v1/connection_summary?orgNumber=${sessionStorage.getItem(
      "subId"
    )}`;
    return apiHelper.get(uri);
  }
  reauthorizeConnection(email, orgNumber, currentPage, size) {
    const uri = `health-monitor-module/adverity/get-reauthorized-connections?email=${email}&orgNumber=${orgNumber}&page=${currentPage}&size=${size}`;
    return apiHelper.get(uri);
  }
  reauthorize(orgNumber, id) {
    const uri = `health-monitor-module/adverity/get-reauthorization-url?orgNumber=${orgNumber}&adverityDatastreamId=${id}`;
    return apiHelper.get(uri);
  }
  getAnalyticsViewId(auth, typeId, accountId, propId) {
    const uri = `health-monitor-module/adverity/get-analytics-viewid?auth=${auth}&typeId=${typeId}&accountId=${accountId}&propId=${propId}`;
    return apiHelper.get(uri);
  }
  authStatus(id, conId, channelName) {
    const uri = `health-monitor-module/connection_isauthorized?connectionId=${id}&connectionType=${conId}&orgNumber=${sessionStorage.getItem(
      "subId"
    )}&channelName=${channelName}`;
    return apiHelper.get(uri);
  }
  getBrandsByUserId(userId) {
    const uri = `user-module/v1/getBrandsByUserId?userId=${userId}`;
    return apiHelper.get(uri);
    //     return axios
    // .get('http://34.226.103.91:8080/api/' + uri, {
    //   headers: this.getHeaders(),
    //   withCredentials: false,
    // })
    // .then(this.checkResponse)
    // .catch(this.handleError);
  }
}
